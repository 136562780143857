import { Col } from "reactstrap";

const ToggleCheckbox = ({
  checkboxes,
  data,
  handleCheckboxChange,
  view,
  name,
  text,
}) => {
  return (
    <Col className="m-5" md="4">
      <p className="h3">{text}</p>
      <label className="custom-toggle custom-toggle-default mr-1">
        <input
          name={name}
          value={checkboxes[name]}
          checked={view ? data[1].questions[name] : checkboxes[name]}
          onChange={(e) => handleCheckboxChange(e, name)}
          type="checkbox"
        />
        <span
          className="custom-toggle-slider rounded-circle"
          data-label-off="No"
          data-label-on="Yes"
        />
      </label>
    </Col>
  );
};

export default ToggleCheckbox;
